import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

export abstract class CynoFieldControl<T> {
    value: T | null;
    readonly stateChanges: Observable<void>;
    readonly id: string | number;
    readonly placeholder: string;
    readonly ngControl: NgControl | null;
    readonly focused: boolean;
    readonly required: boolean;
    readonly errorState: boolean;
    readonly disabled: boolean;
    readonly controlType?: string;
    abstract onContainerClick(event: MouseEvent): void;
}
