import { ChangeDetectorRef, Component, DoCheck, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormGroupDirective,
    NgControl,
    NgForm,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';

export class PasswordStrengthValidator {

    static hasRequiredCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/[@.\-?+!#&=]/g);
            return match ? null : {requiredCharacters: {value: control.value}};
        };
    }

    static hasInvalidCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/[^a-zA-Z0-9\s@.\-?+!#&=]+/g);
            return match ? {invalidCharacters: {value: control.value}} : null;
        };
    }

    static hasNoWordCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/([A-Z]{2})/gi);
            return match ? null : {characters: {value: control.value}};
        };
    }

    static hasNoNumbers(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/([0-9])/g);
            return match ? null : {invalidNumbers: {value: control.value}};
        };
    }

    static isBetween(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const currentLength = value.trim().length;
            return (currentLength < min || currentLength > max) ? {currentLength: {value: currentLength}} : null;
        };
    }
}

class CynoPasswordBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {
    }
}

const _CynoPasswordMixinBase = mixinErrorState(CynoPasswordBase);

class CynoPassword {
}

@Component({
    selector: 'cyno-password',
    templateUrl: './cyno-password.component.html',
    styleUrls: ['./cyno-password.component.scss'],
    host: {
        class: 'form-password',
    },
    providers: [{provide: CynoFieldControl, useExisting: CynoPasswordInputComponent}],
})

export class CynoPasswordInputComponent
    extends _CynoPasswordMixinBase
    implements CynoFieldControl<CynoPassword>, ControlValueAccessor, OnInit, CanUpdateErrorState, DoCheck, OnDestroy {

    get password(): AbstractControl {
        return this.form.get('password');
    }

    get hasRequiredCharacters(): boolean {
        const password = this.password;

        if (password.errors === null) {
            return false;
        }

        return !password.errors.requiredCharacters;
    }

    get isValid(): boolean {
        const password = this.password;

        return password.valid;
    }

    private _onChange: any;
    private _onTouched: any;

    private timeout: any;

    private subscriptions = new Subscription();

    @Input() passwordRequirements: boolean = false;
    @Input() autocomplete?: string = 'current-password';

    id: string | number;
    value: string;
    type: string = 'password';
    placeholder: string;
    required: boolean = true;
    disabled: boolean = false;
    focused: boolean = false;

    form: UntypedFormGroup;

    stateChanges: Subject<void> = new Subject<void>();

    public popoverZichtbaar: boolean = false;
    public hasSpecialCharacter: boolean = false;
    public progressValue: number = 0;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.createForm();
    }

    ngOnInit(): void {
        this.id = this.ngControl.name;

        const passwordChanges = this.password
            .valueChanges
            .subscribe(this._onChange);

        this.subscriptions.add(passwordChanges);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    createForm(): void {
        this.form = new UntypedFormGroup({
            password: new UntypedFormControl('', [
                PasswordStrengthValidator.hasNoWordCharacters(),
                PasswordStrengthValidator.hasNoNumbers(),
                PasswordStrengthValidator.isBetween(8, 40),
                PasswordStrengthValidator.hasInvalidCharacters(),
            ]),
        });
    }

    toggle(): boolean {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        if (this.type === 'password') {
            this.type = 'text';
            this.timeout = setTimeout(() => this.type = 'password', 2000);
        } else {
            this.type = 'password';
        }
        return false;
    }

    writeValue(value: string): void {
        if (value) {
            this.password.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(): void {
    }

    onContainerClick(): void {
    }

    _onFocus(): void {
        if (!this.disabled) {
            this.focused = true;

            if (this.passwordRequirements) {
                this.popoverZichtbaar = true;
            }
        }
    }

    _onBlur(): void {
        this.focused = false;
        this.popoverZichtbaar = false;

        if (!this.disabled && this.id !== 'huidig_wachtwoord') {
            this._onTouched();
            this._changeDetectorRef.markForCheck();
        }

        if (this.id === 'nieuwe_wachtwoord') {
            this._onTouched();
        }
    }

    onInputChange(): void {
        if (this.id === 'nieuwe_wachtwoord' && this.isValid) {
            this._onTouched();
            this._onChange(this.password.value);
            this._changeDetectorRef.markForCheck();
        }

        if (this.password.value) {
            this.hasSpecialCharacter = !!this.password.value.match(/[@.\-?+!#&=]/g);
        }

        this.progressValue = this.checkProgress(this.password.errors);
    }

    private checkProgress(errors: any): number {
        let errorCounter: number = 1;
        if (errors) {
            errorCounter = Object.keys(errors).length + 1;
        }

        if (this.hasSpecialCharacter) {
            errorCounter = errorCounter - 1;
        }

        switch (errorCounter) {
            case 3:
                return 25;
            case 2:
                return 50;
            case 1:
                return 75;
            case 0:
                return 100;
            default:
                return 0;
        }
    }
}
