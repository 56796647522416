import { FormGroupDirective, NgControl, NgForm, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ErrorStateMatcher } from './error-options';

type Constructor<T> = new(...args: any[]) => T;

export interface CanUpdateErrorState {
    readonly stateChanges: Subject<void>;
    errorState: boolean;
    errorStateMatcher: ErrorStateMatcher;
    updateErrorState();
}

export interface HasErrorState {
    _parentFormGroup: FormGroupDirective;
    _parentForm: NgForm;
    _defaultErrorStateMatcher: ErrorStateMatcher;
    ngControl: NgControl;
}

export function mixinErrorState<T extends Constructor<HasErrorState>>(base: T): Constructor<CanUpdateErrorState> & T {
    return class extends base {
        errorState: boolean = false;
        readonly stateChanges = new Subject<void>();
        errorStateMatcher: ErrorStateMatcher;

        constructor(...args: any[]) {
            super(...args);
        }

        updateErrorState() {
            const oldState = this.errorState;
            const parent = this._parentFormGroup || this._parentForm;
            const matcher = this.errorStateMatcher || this._defaultErrorStateMatcher;
            const control = this.ngControl ? this.ngControl.control as UntypedFormControl : null;
            const newState = matcher.isErrorState(control, parent);

            if (newState !== oldState) {
                this.errorState = newState;
                this.stateChanges.next();
            }
        }
    };
}
