<div [formGroup]="form">
    <input
        cynoInput
        [type]="type"
        class="form-password__input"
        formControlName="password"
        [required]="required"
        [id]="id"
        (focus)="_onFocus()"
        (blur)="_onBlur()"
        (input)="onInputChange()"
        [autocomplete]="autocomplete"
    />
    <div class="form-password__icon" *ngIf="password.dirty">
        <a href="#" [ngClass]="type == 'password' ? 'icon-eye' : 'icon-eye-slash'" (click)="toggle()" tabindex="-1"></a>
    </div>
    <div class="form-password__popover" *ngIf="popoverZichtbaar">
        <p class="text text--strong">Heeft uw wachtwoord:</p>
        <list-flat [svg]="true">
            <li>
                <svg *ngIf="password.errors?.characters" class="svg-icon svg-icon--xs svg-icon--secondary">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <svg *ngIf="!password.errors?.characters" class="svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#check-closed"></use>
                </svg>
                Ten minste 2 letters
            </li>
            <li>
                <svg *ngIf="password.errors?.invalidNumbers" class="svg-icon svg-icon--xs svg-icon--secondary">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <svg *ngIf="!password.errors?.invalidNumbers" class="svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#check-closed"></use>
                </svg>
                Ten minste 1 cijfer
            </li>
            <li>
                <svg *ngIf="password.errors?.currentLength" class="svg-icon svg-icon--xs svg-icon--secondary">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <svg *ngIf="!password.errors?.currentLength" class="svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#check-closed"></use>
                </svg>
                8 of meer tekens (en maximaal 40)
            </li>
        </list-flat>
        <ng-container>
            <p class="text text--strong">Maak uw wachtwoord sterker met 1 van deze tekens:</p>
            <list-flat [svg]="true">
                <li class="conditie-optioneel">
                    <svg *ngIf="hasSpecialCharacter" class="svg-icon svg-icon--xs">
                        <use href="assets/icons-ui.svg#check-closed"></use>
                    </svg>
                    <svg *ngIf="!hasSpecialCharacter" class="svg-icon svg-icon--xs svg-icon--secondary">
                        <use href="assets/icons-ui.svg#check-closed"></use>
                    </svg>
                    &#64; . - ? + ! # & =
                </li>
            </list-flat>
        </ng-container>

        <div class="progress">
            <div class="progress__bar" aria-valuemin="0" aria-valuemin="100" [attr.aria-valuenow]="progressValue"></div>
        </div>

        <!-- Als er een fout speciaal teken is ingevuld -->
        <ng-container *ngIf="password.errors?.invalidCharacters">
            <list-flat icon="cross" [svg]="true">
                Eén van de speciale tekens die u heeft opgegeven is niet mogelijk. U kunt naast cijfers en letters de
                volgende tekens gebruiken: &#64; . - ? + ! # & =
            </list-flat>
        </ng-container>

        <!-- Als nog niks ingevuld is -->
        <ng-container
            *ngIf="
                password.errors?.characters &&
                password.errors?.invalidNumbers &&
                password.errors?.currentLength &&
                !password.errors?.invalidCharacters
            "
        >
            <p>
                <small>
                    Kies geen wachtwoord die u ook voor andere websites gebruikt of die gemakkelijk te raden is.
                </small>
            </p>
        </ng-container>

        <!-- Als er geen foute invoer is, maar er nog 1 of 2 letters missen -->
        <ng-container
            *ngIf="
                password.errors?.characters &&
                !password.errors?.invalidNumbers &&
                !password.errors?.invalidCharacters &&
                !password.errors?.currentLength
            "
        >
            <p>
                <small>Voegt u nog 2 letters toe aan het wachtwoord?</small>
            </p>
        </ng-container>

        <!-- Als er geen foute invoer is, maar er nog 1 cijfer mist -->
        <ng-container
            *ngIf="
                password.errors?.invalidNumbers &&
                !password.errors?.characters &&
                !password.errors?.invalidCharacters &&
                !password.errors?.currentLength
            "
        >
            <p>
                <small>In uw wachtwoord ontbreekt nog een cijfer</small>
            </p>
        </ng-container>

        <!-- Als er geen errors zijn maar geen speciaal teken is ingevuld -->
        <ng-container *ngIf="!password.errors && !hasSpecialCharacter">
            <p>
                <small>U kunt uw wachtwoord nog sterker maken met 1 van deze tekens: &#64; . - ? + ! # & =</small>
            </p>
        </ng-container>

        <!-- Als er geen errors zijn en er een speciaal teken is gebruikt -->
        <ng-container *ngIf="!password.errors && hasSpecialCharacter">
            <p>
                <small>Goed bezig ☺️</small>
            </p>
        </ng-container>
    </div>
</div>
